// material-ui
import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';
// import { Box, CircularProgress } from '@mui/material';

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

// ==============================|| Loader ||============================== //

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
  // <Box
  //   sx={{
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     height: '50vh' // 화면의 높이를 100vh로 설정하여 화면 가운데에 배치합니다.
  //   }}
  // >
  //   <CircularProgress color="primary" size={100} />
  // </Box>
);

export default Loader;
