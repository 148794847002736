// auth pages
import './account';

// app pages
import './chat';
import './cart';
import './calendar';
import './kanban';
import './invoice';
import './mes';
import './billing-address';
import './shipping';
import './products';
import './product-reviews';
import './materials';
import './customer';
import './vendor';
import './order';
import './fixedFuel';
import './mobileFuel';
import './waste';
import './steam';
import './processEm';
import './ghgProducts';
import './vehicleDrivingLog';

// menu-items
import './dashboard';
