// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  versionInfos: [],
  versionInfo: null,
  relatedVersionInfos: [],
  reviews: [],
  addresses: [],
  isVersionInfoBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'versionInfo',
  initialState,
  reducers: {
    // review versionInfo popup
    reviewVersionInfoPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getVersionInfosSuccess(state, action) {
    //   state.versionInfo = action.payload;
    // },

    // FILTER PRODUCTS
    filterVersionInfosSuccess(state, action) {
      state.versionInfo = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedVersionInfosSuccess(state, action) {
      state.relatedVersionInfos = action.payload;
    },

    // GET PRODUCT REVIEWS
    getVersionInfoReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isVersionInfoBomDetailOpen = action.payload.isVersionInfoBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get versionInfo details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create VersionInfo
    createVersionInfo(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update versionInfo
    updateVersionInfo(state, action) {
      const NewVersionInfo = action.payload;
      const VersionInfoUpdate = state.lists.map((item) => {
        if (item.id === NewVersionInfo.id) {
          return NewVersionInfo;
        }
        return item;
      });
      state.lists = VersionInfoUpdate;
    },

    // delete versionInfo
    deleteVersionInfo(state, action) {
      const versionInfoId = action.payload;
      const deleteVersionInfo = state.lists.filter((list) => list.id !== versionInfoId);
      state.lists = deleteVersionInfo;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewVersionInfoPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getVersionInfoList() {
  return async () => {
    try {
      // const response = await axios.get('/api/versionInfo/list');
      const objData = {
        path: '/versionInfo/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVersionInfoInsert(NewLists) {
  return async () => {
    try {
      const data = new FormData();
      data.append('version_no', NewLists.version_no);
      data.append('release_date', NewLists.release_date);
      data.append('description', NewLists.description);
      data.append('release_manager', NewLists.release_manager);
      data.append('additional_info', NewLists.additional_info);
      data.append('file', !!NewLists.files && NewLists.files[0]);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/versionInfo/versionInfoUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createVersionInfo(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterVersionInfos(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/versionInfo/filter', { filter });
      dispatch(slice.actions.filterVersionInfosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVersionInfo(id) {
  return async () => {
    try {
      const response = await axios.post('/api/versionInfo/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedVersionInfos(id) {
  return async () => {
    try {
      const response = await axios.post('/api/versionInfo/related', { id });
      dispatch(slice.actions.getRelatedVersionInfosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVersionInfoReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getVersionInfoReviewsSuccess(response.data.versionInfoReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVersionInfoSingleList(versionInfoId) {
  return async () => {
    try {
      const response = await axios.post('/api/versionInfo/list/single', versionInfoId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVersionInfoUpdate(NewLists, id, flag) {
  return async () => {
    try {
      const data = new FormData();
      data.append('id', NewLists.id);
      data.append('version_no', NewLists.version_no);
      data.append('release_date', NewLists.release_date);
      data.append('description', NewLists.description);
      data.append('release_manager', NewLists.release_manager);
      data.append('additional_info', NewLists.additional_info);
      data.append('file', !!NewLists.files && NewLists.files[0]);

      let response;

      if (flag === 'Y') {
        // `Y` 플래그에 대한 처리
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/versionInfo/versionInfoUpdate`, data, config);
      } else {
        // `Y` 플래그가 아닐 때의 처리
        const objData = {
          path: '/versionInfo/update',
          data: NewLists
        };

        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateVersionInfo(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getVersionInfoDelete(versionInfoId) {
  return async () => {
    try {
      const keyId = {
        id: versionInfoId
      };
      const objData = {
        path: '/versionInfo/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteVersionInfo(versionInfoId));
        return response.data;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
