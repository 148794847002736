// project imports
import services from 'utils/mockAdapter';

// project imports
import vendor from 'data/vendor.json';

// third-party
// import _ from 'lodash';

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/vendor/list').reply(200, { vendor });
