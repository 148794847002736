// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  esgMonthlyCarbonEmissionss: [],
  esgMonthlyCarbonEmissions: null,
  relatedEsgMonthlyCarbonEmissionss: [],
  reviews: [],
  addresses: [],
  isEsgMonthlyCarbonEmissionsBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'esgMonthlyCarbonEmissions',
  initialState,
  reducers: {
    // review esgMonthlyCarbonEmissions popup
    reviewEsgMonthlyCarbonEmissionsPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getEsgMonthlyCarbonEmissionssSuccess(state, action) {
    //   state.esgMonthlyCarbonEmissions = action.payload;
    // },

    // FILTER PRODUCTS
    filterEsgMonthlyCarbonEmissionssSuccess(state, action) {
      state.esgMonthlyCarbonEmissions = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    getMonthlyList(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedEsgMonthlyCarbonEmissionssSuccess(state, action) {
      state.relatedEsgMonthlyCarbonEmissionss = action.payload;
    },

    // GET PRODUCT REVIEWS
    getEsgMonthlyCarbonEmissionsReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isEsgMonthlyCarbonEmissionsBomDetailOpen = action.payload.isEsgMonthlyCarbonEmissionsBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get esgMonthlyCarbonEmissions details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create EsgMonthlyCarbonEmissions
    createEsgMonthlyCarbonEmissions(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },

    createEsgMonthlyCarbonEmissionsExcel(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update esgMonthlyCarbonEmissions
    updateEsgMonthlyCarbonEmissions(state, action) {
      const NewEsgMonthlyCarbonEmissions = action.payload;
      const EsgMonthlyCarbonEmissionsUpdate = state.lists.map((item) => {
        if (item.id === NewEsgMonthlyCarbonEmissions.id) {
          return NewEsgMonthlyCarbonEmissions;
        }
        return item;
      });
      state.lists = EsgMonthlyCarbonEmissionsUpdate;
    },

    updateEsgMonthlyProduction(state, action) {
      const NewEsgMonthlyCarbonEmissions = action.payload;
      const EsgMonthlyCarbonEmissionsUpdate = state.lists.map((item) => {
        if (item.id === NewEsgMonthlyCarbonEmissions.id) {
          return NewEsgMonthlyCarbonEmissions;
        }
        return item;
      });
      state.lists = EsgMonthlyCarbonEmissionsUpdate;
    },

    // delete esgMonthlyCarbonEmissions
    deleteEsgMonthlyCarbonEmissions(state, action) {
      const esgMonthlyCarbonEmissionsId = action.payload;
      const deleteEsgMonthlyCarbonEmissions = state.lists.filter((list) => list.id !== esgMonthlyCarbonEmissionsId);
      state.lists = deleteEsgMonthlyCarbonEmissions;
    },

    deleteEsgMonthlyProduction(state, action) {
      const esgMonthlyCarbonEmissionsId = action.payload;
      const deleteEsgMonthlyProduction = state.lists.filter((list) => list.id !== esgMonthlyCarbonEmissionsId);
      state.lists = deleteEsgMonthlyProduction;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewEsgMonthlyCarbonEmissionsPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  getMonthlyList,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getEsgMonthlyCarbonEmissionsList() {
  return async () => {
    try {
      const objData = {
        path: '/esgMonthlyCarbonEmissions/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyProductionList() {
  return async () => {
    try {
      const objData = {
        path: '/esgMonthlyCarbonEmissions/esgMonthlyProductionList'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getMonthlyList(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyCarbonEmissionsInsert(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/esgMonthlyCarbonEmissions/esgMonthlyCarbonEmissionsUpload`,
        NewLists, // JSON 데이터를 직접 전송
        config
      );

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgMonthlyCarbonEmissions(result));
        return { success: true, data: result };
      } else if (response.status === 201) {
        alert(response.data.message); // 201 상태 코드일 때 경고 메시지 표시
        return { success: false, message: response.data.message };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterEsgMonthlyCarbonEmissionss(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/esgMonthlyCarbonEmissions/filter', { filter });
      dispatch(slice.actions.filterEsgMonthlyCarbonEmissionssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyCarbonEmissions(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgMonthlyCarbonEmissions/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedEsgMonthlyCarbonEmissionss(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgMonthlyCarbonEmissions/related', { id });
      dispatch(slice.actions.getRelatedEsgMonthlyCarbonEmissionssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyCarbonEmissionsReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getEsgMonthlyCarbonEmissionsReviewsSuccess(response.data.esgMonthlyCarbonEmissionsReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyCarbonEmissionsSingleList(esgMonthlyCarbonEmissionsId) {
  return async () => {
    try {
      const response = await axios.post('/api/esgMonthlyCarbonEmissions/list/single', esgMonthlyCarbonEmissionsId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getEsgMonthlyCarbonEmissionsUpdate(NewLists) {
//   return async (dispatch) => {
//     try {
//       const data = new FormData();

//       data.append('id', NewLists.id);
//       data.append('productionAmount', NewLists.productionAmount);
//       data.append('salesAmount', NewLists.salesAmount);
//       data.append('productionMonth', NewLists.productionMonth);

//       const response = await axios.post(
//         `${process.env.REACT_APP_API_BASE_URL}/esgMonthlyCarbonEmissions/esgMonthlyCarbonEmissionsUpdate`,
//         data,
//         config
//       );

//       if (response.status === 200) {
//         const result = response.data;
//         dispatch(slice.actions.updateEsgMonthlyCarbonEmissions(result));
//         return { success: true, data: result };
//       } else {
//         alert(response.data.message);
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       console.error('Update error:', error);
//       dispatch(slice.actions.hasError(error));
//       return { success: false, error };
//     }
//   };
// }

export function getEsgMonthlyCarbonEmissionsUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/esgMonthlyCarbonEmissions/esgMonthlyCarbonEmissionsUpdate`,
        NewLists, // JSON 데이터를 직접 전송
        config
      );

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateEsgMonthlyCarbonEmissions(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgMonthlyProductionUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();

      data.append('id', NewLists.id);
      data.append('productionAmount', NewLists.productionAmount);
      data.append('salesAmount', NewLists.salesAmount);
      data.append('productionMonth', NewLists.productionMonth);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/esgMonthlyCarbonEmissions/esgMonthlyProductionUpdate`,
        data,
        config
      );

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateEsgMonthlyProduction(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgMonthlyCarbonEmissionsExcelUpload(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('excels', JSON.stringify(NewLists.excels));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgMonthlyCarbonEmissions/excelUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgMonthlyCarbonEmissionsExcel(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgMonthlyCarbonEmissionsDelete(esgMonthlyCarbonEmissionsId) {
  return async () => {
    try {
      const keyId = {
        id: esgMonthlyCarbonEmissionsId
      };
      const objData = {
        path: '/esgMonthlyCarbonEmissions/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgMonthlyCarbonEmissions(esgMonthlyCarbonEmissionsId));
        return { success: true };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyProductionDelete(esgMonthlyCarbonEmissionsId) {
  return async () => {
    try {
      const keyId = {
        id: esgMonthlyCarbonEmissionsId
      };
      const objData = {
        path: '/esgMonthlyCarbonEmissions/esgMonthlyProductionDelete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgMonthlyCarbonEmissions(esgMonthlyCarbonEmissionsId));
        return { success: true };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
