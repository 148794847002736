import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const EsgSupplyChainList = Loadable(lazy(() => import('pages/esg/esgSupplyChain/list')));
const EsgSupplyChainCreate = Loadable(lazy(() => import('pages/esg/esgSupplyChain/create')));
const EsgSupplyChainEdit = Loadable(lazy(() => import('pages/esg/esgSupplyChain/edit')));

const EsgProductSupplyChainList = Loadable(lazy(() => import('pages/esg/esgProductSupplyChain/list')));
const EsgProductSupplyChainCreate = Loadable(lazy(() => import('pages/esg/esgProductSupplyChain/create')));
const EsgProductSupplyChainEdit = Loadable(lazy(() => import('pages/esg/esgProductSupplyChain/edit')));

const EsgProductSupplyChainCarbonList = Loadable(lazy(() => import('pages/esg/esgProductSupplyChainCarbon/list')));
const EsgProductSupplyChainCarbonCreate = Loadable(lazy(() => import('pages/esg/esgProductSupplyChainCarbon/create')));
const EsgProductSupplyChainCarbonEdit = Loadable(lazy(() => import('pages/esg/esgProductSupplyChainCarbon/edit')));

// ==============================|| eag ROUTING ||============================== //

const EsgSupplyRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'esgSupplyChain',
          children: [
            {
              path: 'esgProductSupplyChain',
              children: [
                {
                  path: 'list',
                  element: <EsgProductSupplyChainList />
                },
                {
                  path: 'create',
                  element: <EsgProductSupplyChainCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgProductSupplyChainEdit />
                }
              ]
            },
            {
              path: 'esgSupplyChain',
              children: [
                {
                  path: 'list',
                  element: <EsgSupplyChainList />
                },
                {
                  path: 'create',
                  element: <EsgSupplyChainCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgSupplyChainEdit />
                }
              ]
            },
            {
              path: 'esgProductSupplyChainCarbon',
              children: [
                {
                  path: 'list',
                  element: <EsgProductSupplyChainCarbonList />
                },
                {
                  path: 'create',
                  element: <EsgProductSupplyChainCarbonCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgProductSupplyChainCarbonEdit />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default EsgSupplyRoutes;
