// import { lazy } from "react";
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import MinimalLayout from "layout/MinimalLayout";
import MainLayout from 'layout/MainLayout';
// import { RequireAuth } from 'utils/RequireAuth';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - Profile
const Profile = Loadable(lazy(() => import('pages/profile')));
const Password = Loadable(lazy(() => import('pages/password')));
const ProfileCompanyDetails = Loadable(lazy(() => import('pages/profile/profileCompanyDetails')));
const ProfileCompanyEdit = Loadable(lazy(() => import('pages/profile/profileCompanyEdit')));
const ApiKeys = Loadable(lazy(() => import('pages/profile/apiKeys')));

// const ExcelUpload = Loadable(lazy(() => import("pages/fmeasetting")));

// ==============================|| AUTH ROUTING ||============================== //

const ProfileRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <Profile />
        </AuthGuard>
      )
    },
    {
      path: 'password',
      element: (
        <AuthGuard>
          <Password />
        </AuthGuard>
      )
    },
    {
      path: 'profileCompanyDetails',
      element: (
        <AuthGuard>
          <ProfileCompanyDetails />
        </AuthGuard>
      )
    },
    {
      path: 'profileCompanyEdit',
      element: (
        <AuthGuard>
          <ProfileCompanyEdit />
        </AuthGuard>
      )
    },
    {
      path: 'apiKeys',
      element: (
        <AuthGuard>
          <ApiKeys />
        </AuthGuard>
      )
    }
    // {
    // path: "excelupload",
    // element: <RequireAuth><ExcelUpload /></RequireAuth>,
    // },
  ]
};

export default ProfileRoutes;
