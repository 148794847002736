// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  qnas: [],
  qna: null,
  relatedQnas: [],
  reviews: [],
  addresses: [],
  isQnaBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'qna',
  initialState,
  reducers: {
    // review qna popup
    reviewQnaPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getQnasSuccess(state, action) {
    //   state.qna = action.payload;
    // },

    // FILTER PRODUCTS
    filterQnasSuccess(state, action) {
      state.qna = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedQnasSuccess(state, action) {
      state.relatedQnas = action.payload;
    },

    // GET PRODUCT REVIEWS
    getQnaReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isQnaBomDetailOpen = action.payload.isQnaBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get qna details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create Qna
    createQna(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update qna
    updateQna(state, action) {
      const NewQna = action.payload;
      const QnaUpdate = state.lists.map((item) => {
        if (item.id === NewQna.id) {
          return NewQna;
        }
        return item;
      });
      state.lists = QnaUpdate;
    },

    // delete qna
    deleteQna(state, action) {
      const qnaId = action.payload;
      const deleteQna = state.lists.filter((list) => list.id !== qnaId);
      state.lists = deleteQna;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewQnaPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getQnaList() {
  return async () => {
    try {
      const objData = {
        path: '/qna/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQnaInsert(NewLists) {
  // console.log('NewLists', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();
      // 기존 필드 추가
      data.append('title', NewLists.title);
      data.append('description', NewLists.description);
      data.append('category', NewLists.category);
      data.append('is_announcement', NewLists.is_announcement);
      data.append('user_id', NewLists.user_id);

      // 여러 파일 처리
      if (NewLists.files && NewLists.files.length) {
        NewLists.files.forEach((file) => {
          data.append('files', file); // 'files[${index}]' 대신 'files' 사용
        });
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qna/qnaUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createQna(result));
        return { success: true, data: result };
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterQnas(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/qna/filter', { filter });
      dispatch(slice.actions.filterQnasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQna(id) {
  return async () => {
    try {
      const response = await axios.post('/api/qna/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedQnas(id) {
  return async () => {
    try {
      const response = await axios.post('/api/qna/related', { id });
      dispatch(slice.actions.getRelatedQnasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQnaReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getQnaReviewsSuccess(response.data.qnaReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQnaSingleList(qnaId) {
  return async () => {
    try {
      const response = await axios.post('/api/qna/list/single', qnaId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQnaUpdate(NewLists, flag) {
  return async (dispatch) => {
    try {
      // 공통 필드 설정
      const commonFields = {
        id: NewLists.id,
        title: NewLists.title,
        description: NewLists.description,
        category: NewLists.category,
        is_announcement: NewLists.is_announcement,
        user_id: NewLists.user_id
      };

      if (flag === 'Y') {
        // formData 사용 - 파일 업로드가 필요한 경우
        const objData = new FormData();
        Object.keys(commonFields).forEach((key) => {
          objData.append(key, commonFields[key]);
        });

        // 추가된 파일 처리
        if (NewLists.addedFiles && NewLists.addedFiles.length) {
          NewLists.addedFiles.forEach((file) => {
            objData.append('addedFiles', file); // 추가된 파일들
          });
        }

        // 삭제할 파일 목록 처리
        if (NewLists.deletedFiles && NewLists.deletedFiles.length) {
          objData.append('deletedFiles', JSON.stringify(NewLists.deletedFiles)); // 삭제할 파일들의 목록
        }

        // API 요청
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qna/qnaUpdate`, objData, config);

        // 응답 처리
        if (response.status === 200) {
          const result = response.data;
          dispatch(slice.actions.updateQna(result));
          return { success: true, data: result };
        } else {
          return { success: false, message: response.data.message };
        }
      } else {
        // 'Y'가 아닌 경우 기존 로직 처리
        const objData = {
          path: '/qna/update',
          id: NewLists.id,
          title: NewLists.title,
          description: NewLists.description,
          category: NewLists.category,
          is_announcement: NewLists.is_announcement,
          user_id: NewLists.user_id
        };

        // console.log('objData', objData);
        const response = await ApiService.post(objData);

        // 응답 처리
        if (response.status === 200) {
          const result = response.data;
          dispatch(slice.actions.updateQna(result));
          return { success: true, data: result };
        } else {
          return { success: false, message: response.data.message };
        }
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error: error.message };
    }
  };
}

export function getQnaDelete(qnaId) {
  return async () => {
    try {
      const keyId = {
        id: qnaId
      };
      const objData = {
        path: '/qna/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteQna(qnaId));
        return { success: true };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
