// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  esgCustomers: [],
  esgCustomer: null,
  relatedEsgCustomers: [],
  reviews: [],
  addresses: [],
  isEsgCustomerBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'esgCustomer',
  initialState,
  reducers: {
    // review esgCustomer popup
    reviewEsgCustomerPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getEsgCustomersSuccess(state, action) {
    //   state.esgCustomer = action.payload;
    // },

    // FILTER PRODUCTS
    filterEsgCustomersSuccess(state, action) {
      state.esgCustomer = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedEsgCustomersSuccess(state, action) {
      state.relatedEsgCustomers = action.payload;
    },

    // GET PRODUCT REVIEWS
    getEsgCustomerReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isEsgCustomerBomDetailOpen = action.payload.isEsgCustomerBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get esgCustomer details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create EsgCustomer
    createEsgCustomer(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update esgCustomer
    updateEsgCustomer(state, action) {
      const NewEsgCustomer = action.payload;
      const EsgCustomerUpdate = state.lists.map((item) => {
        if (item.id === NewEsgCustomer.id) {
          return NewEsgCustomer;
        }
        return item;
      });
      state.lists = EsgCustomerUpdate;
    },

    // delete esgCustomer
    deleteEsgCustomer(state, action) {
      const esgCustomerId = action.payload;
      const deleteEsgCustomer = state.lists.filter((list) => list.id !== esgCustomerId);
      state.lists = deleteEsgCustomer;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewEsgCustomerPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getEsgCustomerList() {
  return async () => {
    try {
      const objData = {
        path: '/esgCustomer/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('getEsgCustomerList', response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgCustomerInsert(NewLists) {
  // console.log('NewLists', NewLists);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgCustomer/insert`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgCustomer(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgCustomerUpdate(NewLists) {
  // console.log('NewLists', NewLists);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgCustomer/update`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateEsgCustomer(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterEsgCustomers(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/esgCustomer/filter', { filter });
      dispatch(slice.actions.filterEsgCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgCustomer(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgCustomer/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedEsgCustomers(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgCustomer/related', { id });
      dispatch(slice.actions.getRelatedEsgCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgCustomerReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getEsgCustomerReviewsSuccess(response.data.esgCustomerReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgCustomerSingleList(esgCustomerId) {
  return async () => {
    try {
      const response = await axios.post('/api/esgCustomer/list/single', esgCustomerId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgCustomerDelete(esgCustomerId) {
  return async () => {
    try {
      const keyId = {
        id: esgCustomerId
      };
      const objData = {
        path: '/esgCustomer/delete',
        data: keyId
      };

      // console.log('objData', objData);

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgCustomer(esgCustomerId));
        return { success: true, data: response.data };
      } else {
        alert(response.data.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
