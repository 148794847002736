// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  apiKeyss: [],
  apiKeys: null,
  relatedApiKeyss: [],
  reviews: [],
  addresses: [],
  isApiKeysBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    // review apiKeys popup
    reviewApiKeysPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getApiKeyssSuccess(state, action) {
    //   state.apiKeys = action.payload;
    // },

    // FILTER PRODUCTS
    filterApiKeyssSuccess(state, action) {
      state.apiKeys = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedApiKeyssSuccess(state, action) {
      state.relatedApiKeyss = action.payload;
    },

    // GET PRODUCT REVIEWS
    getApiKeysReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isApiKeysBomDetailOpen = action.payload.isApiKeysBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get apiKeys details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create ApiKeys
    createApiKeys(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update apiKeys
    updateApiKeys(state, action) {
      const NewApiKeys = action.payload;
      const ApiKeysUpdate = state.lists.map((item) => {
        if (item.id === NewApiKeys.id) {
          return NewApiKeys;
        }
        return item;
      });
      state.lists = ApiKeysUpdate;
    },

    // delete apiKeys
    deleteApiKeys(state, action) {
      const apiKeysId = action.payload;
      const deleteApiKeys = state.lists.filter((list) => list.id !== apiKeysId);
      state.lists = deleteApiKeys;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewApiKeysPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getApiKeysList() {
  return async () => {
    try {
      const objData = {
        path: '/apiKeys/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApiKeysInsert(NewLists) {
  console.log('Reducers NewLists Data', NewLists);

  return async (dispatch) => {
    // Make sure to include dispatch here
    try {
      const data = new FormData();
      data.append('usage_type', NewLists.usage_type);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/apiKeys/apiKeysUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createApiKeys(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterApiKeyss(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/apiKeys/filter', { filter });
      dispatch(slice.actions.filterApiKeyssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApiKeys(id) {
  return async () => {
    try {
      const response = await axios.post('/api/apiKeys/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedApiKeyss(id) {
  return async () => {
    try {
      const response = await axios.post('/api/apiKeys/related', { id });
      dispatch(slice.actions.getRelatedApiKeyssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApiKeysReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getApiKeysReviewsSuccess(response.data.apiKeysReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApiKeysSingleList(apiKeysId) {
  return async () => {
    try {
      const response = await axios.post('/api/apiKeys/list/single', apiKeysId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApiKeysUpdate(NewLists, id, flag) {
  return async () => {
    try {
      const data = new FormData();
      data.append('id', NewLists.id);
      data.append('user_id', NewLists.user_id);
      data.append('diecasting_column', NewLists.diecasting_column);

      let response;

      if (flag === 'Y') {
        // `Y` 플래그에 대한 처리
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/apiKeys/apiKeysUpdate`, data, config);
      } else {
        // `Y` 플래그가 아닐 때의 처리
        const objData = {
          path: '/apiKeys/update',
          data: NewLists
        };

        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateApiKeys(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getApiKeysDelete(apiKeysId) {
  return async () => {
    try {
      const keyId = {
        id: apiKeysId
      };
      const objData = {
        path: '/apiKeys/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteApiKeys(apiKeysId));
        return response.data;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
