// project imports
import services from 'utils/mockAdapter';

// project imports
import customer from 'data/customer.json';

// third-party
// import _ from 'lodash';

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/customer/list').reply(200, { customer });
