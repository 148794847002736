import api from './ApiAdaptor';

class ApiService {
  async post(objectData) {
    try {
      const response = await api.post(objectData.path, objectData);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async put(objectData) {
    try {
      const response = await api.put(objectData.path, objectData);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async get(objectData) {
    const params = { params: objectData };
    try {
      const response = await api.get(objectData.path, params);
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default new ApiService();
