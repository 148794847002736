// lms
// import lms from './lms';
// import aixai from './aixai';

// esg
import esg from './esg';
import esgSupplyChain from './esgSupplyChain';
// GHG
// import GHG from './GHG';

// diecasting
// bbs
// import bbs from './bbs';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [esg, esgSupplyChain]
};

export default menuItems;
