// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  newss: [],
  news: null,
  relatedNewss: [],
  reviews: [],
  addresses: [],
  isNewsBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // review news popup
    reviewNewsPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getNewssSuccess(state, action) {
    //   state.news = action.payload;
    // },

    // FILTER PRODUCTS
    filterNewssSuccess(state, action) {
      state.news = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    getOGLists(state, action) {
      state.lists = action.payload;
    },

    getOGOpenLists(state, action) {
      state.lists = action.payload;
    },

    getBlockOGLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedNewssSuccess(state, action) {
      state.relatedNewss = action.payload;
    },

    // GET PRODUCT REVIEWS
    getNewsReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isNewsBomDetailOpen = action.payload.isNewsBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get news details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create News
    createNews(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update news
    updateNews(state, action) {
      const NewNews = action.payload;
      const NewsUpdate = state.lists.map((item) => {
        if (item.id === NewNews.id) {
          return NewNews;
        }
        return item;
      });
      state.lists = NewsUpdate;
    },

    // delete news
    deleteNews(state, action) {
      const newsId = action.payload;
      const deleteNews = state.lists.filter((list) => list.id !== newsId);
      state.lists = deleteNews;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewNewsPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  getOGLists,
  getOGOpenLists,
  getBlockOGLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getNewsList() {
  return async () => {
    try {
      const objData = {
        path: '/news/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewsOGList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/news/OGlist'
      };
      const response = await ApiService.get(objData);
      if (response.data && Array.isArray(response.data)) {
        dispatch(slice.actions.getOGLists(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      // 직렬화 가능한 문자열을 사용
      dispatch(slice.actions.hasError(error.message || '데이터 가져오기 실패'));
    }
  };
}

export function getNewsOGOpenList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/news/OGOpenlist'
      };
      const response = await ApiService.get(objData);
      if (response.data && Array.isArray(response.data)) {
        dispatch(slice.actions.getOGOpenLists(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      // 직렬화 가능한 문자열을 사용
      dispatch(slice.actions.hasError(error.message || '데이터 가져오기 실패'));
    }
  };
}

export function getNewsBlockOGList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/news/BlockOGlist'
      };
      const response = await ApiService.get(objData);
      if (response.data && Array.isArray(response.data)) {
        dispatch(slice.actions.getBlockOGLists(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      // 직렬화 가능한 문자열을 사용
      dispatch(slice.actions.hasError(error.message || '데이터 가져오기 실패'));
    }
  };
}

export function getNewsInsert(NewLists) {
  // console.log('Reducers NewLists Data', NewLists);

  return async () => {
    // Make sure to include dispatch here
    try {
      const data = new FormData();
      data.append('category', NewLists.category);
      data.append('news_date', NewLists.news_date);
      data.append('title', NewLists.title);
      data.append('description', NewLists.description);
      data.append('source', NewLists.source);
      data.append('source_link', NewLists.source_link);
      data.append('user_id', NewLists.user_id);
      data.append('is_announcement', NewLists.is_announcement);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/news/newsUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createNews(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterNewss(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/news/filter', { filter });
      dispatch(slice.actions.filterNewssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNews(id) {
  return async () => {
    try {
      const response = await axios.post('/api/news/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedNewss(id) {
  return async () => {
    try {
      const response = await axios.post('/api/news/related', { id });
      dispatch(slice.actions.getRelatedNewssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewsReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getNewsReviewsSuccess(response.data.newsReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewsSingleList(newsId) {
  return async () => {
    try {
      const response = await axios.post('/api/news/list/single', newsId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getNewsUpdate(NewLists, id, flag) {
//   return async () => {
//     try {
//       const data = new FormData();
//       data.append('id', NewLists.id);
//       data.append('title', NewLists.title);
//       data.append('description', NewLists.description);
//       data.append('is_announcement', NewLists.is_announcement);
//       data.append('user_id', NewLists.user_id);

//       let response;

//       if (flag === 'Y') {
//         // `Y` 플래그에 대한 처리
//         response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/news/newsUpdate`, data, config);
//       } else {
//         // `Y` 플래그가 아닐 때의 처리
//         const objData = {
//           path: '/news/update',
//           data: NewLists
//         };

//         response = await ApiService.post(objData);
//       }

//       if (response.status === 200) {
//         const result = response.data;
//         dispatch(slice.actions.updateNews(result));
//         return { success: true, data: result };
//       } else {
//         alert(response.data.message);
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       console.error('Update error:', error);
//       dispatch(slice.actions.hasError(error));
//       return { success: false, error };
//     }
//   };
// }

export function getNewsUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/news/update',
        data: NewLists
      };

      const response = await ApiService.post(objData);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateNews(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

// export function getNewsDelete(newsId) {
//   return async () => {
//     try {
//       const keyId = {
//         id: newsId
//       };
//       const objData = {
//         path: '/news/delete',
//         data: keyId
//       };

//       const response = await ApiService.post(objData);
//       if (response.status === 200) {
//         dispatch(slice.actions.deleteNews(newsId));
//       } else {
//         alert(result.message);
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getNewsDelete(newsId) {
  return async (dispatch) => {
    try {
      const keyId = {
        id: newsId
      };
      const objData = {
        path: '/news/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteNews(newsId));
      } else {
        dispatch(slice.actions.hasError(response.data.message || '삭제 중 오류 발생'));
      }
    } catch (error) {
      // 오류 메시지를 전달
      dispatch(slice.actions.hasError(error.message || '네트워크 오류 발생'));
    }
  };
}
