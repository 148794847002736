/* eslint-disable no-undef */
// project imports
import services from 'utils/mockAdapter';
// import { mobileFuel } from 'data/e-commerce';
import mobileFuel from 'data/mobileFuel.json';

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/mobileFuel/list').reply(200, { mobileFuel });

services.onPost('/api/mobileFuel/filter').reply((config) => {
  try {
    const { filter } = JSON.parse(config.data);

    if (filter.sort === 'high') {
      mobileFuel.sort((a, b) => Number(b.amount) - Number(a.amount));
    }

    if (filter.sort === 'low') {
      mobileFuel.sort((a, b) => Number(a.amount) - Number(b.amount));
    }

    // if (filter.sort === 'popularity') {
    //   mobileFuel.sort((a, b) => Number(b.popularity) - Number(a.popularity));
    // }

    // if (filter.sort === 'stock') {
    //   mobileFuel.sort((a, b) => Number(b.stock) - Number(a.stock));
    // }

    // if (filter.sort === 'new') {
    //   mobileFuel.sort((a, b) => Number(b.new) - Number(a.new));
    // }

    const results = mobileFuel.filter((mobileFuel) => {
      let searchMatches = true;

      if (filter.search) {
        const properties = ['name', 'fuel', 'unit', 'amount'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (mobileFuel[property] && mobileFuel[property].toString().toLowerCase().includes(filter.search.toString().toLowerCase())) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          searchMatches = false;
        }
      }

      // const genderMatches = filter.gender.length > 0 ? filter.gender.some((item) => item === mobileFuel.gender) : true;
      // const categoriesMatches =
      //   filter.categories.length > 0 && filter.categories.some((category) => category !== 'all')
      //     ? filter.categories.some((category) => mobileFuel.categories.some((item) => item === category))
      //     : true;
      // const colorsMatches = filter.colors.length > 0 ? filter.colors.some((color) => mobileFuel.colors.some((item) => item === color)) : true;

      // const minMax = filter.price ? filter.price.split('-') : '';
      // const priceMatches = filter.price ? mobileFuel.amount >= minMax[0] && mobileFuel.amount <= minMax[1] : true;
      // const ratingMatches = filter.rating > 0 ? mobileFuel.rating >= filter.rating : true;

      return searchMatches;
    });

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/mobileFuel/details').reply((config) => {
  try {
    const { id } = JSON.parse(config.data);

    let results;
    if (id === 'default') {
      [results] = mobileFuel;
    } else {
      // eslint-disable-next-line
      [results] = mobileFuel?.filter((mobileFuel) => mobileFuel.id === Number(id));
    }

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/mobileFuel/related').reply((config) => {
  try {
    const { id } = JSON.parse(config.data);

    const results = mobileFuel.filter((mobileFuel) => mobileFuel.id !== Number(id));

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/mobileFuel/list/single').reply((config) => {
  try {
    const result = mobileFuel.find((e) => e.id === Number(config.data));
    return [200, result];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/mobileFuel/insert').reply((config) => {
  try {
    let list = JSON.parse(config.data);
    list = {
      ...list,
      id: mobileFuel.length + 1
    };
    mobileFuel.push(list);
    return [200, { list }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/mobileFuel/update').reply((config) => {
  try {
    const list = JSON.parse(config.data);

    let NewProduct = null;
    _.map(mobileFuel, (_list) => {
      if (_list.id === list.id) {
        _.assign(_list, { ...list });
        NewProduct = _list;
      }
      return _list;
    });

    return [200, { NewProduct }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
