import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import logoDark from 'assets/images/wace-main-dark.svg';
import logo from 'assets/images/wace-main2.svg';
import useAuth from 'hooks/useAuth';

const LogoMain = () => {
  const { user } = useAuth();
  // console.log('user.logo', user.logo);
  const theme = useTheme();

  // user가 null이거나 user.logo가 없는 경우, 기본 로고 이미지를 사용합니다.
  const logoSrc = user ? user.logo || (theme.palette.mode === 'dark' ? logoDark : logo) : theme.palette.mode === 'dark' ? logoDark : logo;

  return <img src={logoSrc} alt="Logo" width="30" />;
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
