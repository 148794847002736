// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import cartReducer from './cart';
import dashboardReducer from './dashboard';
import versionInfo from './versionInfo';
import contactUs from './contactUs';
import qna from './qna';
import qnaComments from './qnaComments';
import news from './news';
import userSetup from './userSetup';
import company from './company';
import apiKeys from './apiKeys';
import esgProduct from './esgProduct';
import esgProductSupplyChain from './esgProductSupplyChain';
import esgMonthlyCarbonEmissions from './esgMonthlyCarbonEmissions';
import esgProductCarbon from './esgProductCarbon';
import esgCustomer from './esgCustomer';
import esgSupplyChain from './esgSupplyChain';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-js-'
    },
    cartReducer
  ),
  dashboard: dashboardReducer,
  versionInfo,
  contactUs,
  qna,
  qnaComments,
  news,
  userSetup,
  company,
  apiKeys,
  esgProduct,
  esgProductSupplyChain,
  esgMonthlyCarbonEmissions,
  esgProductCarbon,
  esgCustomer,
  esgSupplyChain
});

export default reducers;
