// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  companys: [],
  company: null,
  relatedCompanys: [],
  reviews: [],
  addresses: [],
  isCompanyBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // review company popup
    reviewCompanyPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getCompanysSuccess(state, action) {
    //   state.company = action.payload;
    // },

    // FILTER PRODUCTS
    filterCompanysSuccess(state, action) {
      state.company = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    getOGLists(state, action) {
      state.lists = action.payload;
    },

    getOGOpenLists(state, action) {
      state.lists = action.payload;
    },

    getBlockOGLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedCompanysSuccess(state, action) {
      state.relatedCompanys = action.payload;
    },

    // GET PRODUCT REVIEWS
    getCompanyReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isCompanyBomDetailOpen = action.payload.isCompanyBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get company details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create Company
    createCompany(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update company
    updateCompany(state, action) {
      const NewCompany = action.payload;
      const CompanyUpdate = state.lists.map((item) => {
        if (item.id === NewCompany.id) {
          return NewCompany;
        }
        return item;
      });
      state.lists = CompanyUpdate;
    },

    // delete company
    deleteCompany(state, action) {
      const companyId = action.payload;
      const deleteCompany = state.lists.filter((list) => list.id !== companyId);
      state.lists = deleteCompany;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewCompanyPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  getOGLists,
  getOGOpenLists,
  getBlockOGLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getCompanyList() {
  return async () => {
    try {
      const objData = {
        path: '/company/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyInsert(NewLists) {
  // console.log('Reducers NewLists Data', NewLists);

  return async (dispatch) => {
    // Make sure to include dispatch here
    try {
      const data = new FormData();
      data.append('category', NewLists.category);
      data.append('company_date', NewLists.company_date);
      data.append('title', NewLists.title);
      data.append('description', NewLists.description);
      data.append('source', NewLists.source);
      data.append('source_link', NewLists.source_link);
      data.append('user_id', NewLists.user_id);
      data.append('is_announcement', NewLists.is_announcement);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/company/companyUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createCompany(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterCompanys(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/company/filter', { filter });
      dispatch(slice.actions.filterCompanysSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompany(id) {
  return async () => {
    try {
      const response = await axios.post('/api/company/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedCompanys(id) {
  return async () => {
    try {
      const response = await axios.post('/api/company/related', { id });
      dispatch(slice.actions.getRelatedCompanysSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getCompanyReviewsSuccess(response.data.companyReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanySingleList(companyId) {
  return async () => {
    try {
      const response = await axios.post('/api/company/list/single', companyId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyUpdate(NewLists, flag) {
  return async (dispatch) => {
    try {
      // 공통 필드 설정
      const commonFields = {
        id: NewLists.id,
        compName: NewLists.compName,
        compNumber: NewLists.compNumber,
        ceoName: NewLists.ceoName,
        custNo: NewLists.custNo,
        serviceKey: NewLists.serviceKey,
        createUser: NewLists.createUser,
        email: NewLists.email,
        phone: NewLists.phone,
        fax: NewLists.fax,
        zip_code: NewLists.zip_code,
        address: NewLists.address,
        address1: NewLists.address1,
        status: NewLists.status
      };

      if (flag === 'Y') {
        // FormData 사용 - 파일 업로드가 필요한 경우
        const formData = new FormData();
        Object.keys(commonFields).forEach((key) => {
          formData.append(key, commonFields[key]);
        });

        // 추가된 파일 처리
        if (NewLists.addedFiles && NewLists.addedFiles.length) {
          NewLists.addedFiles.forEach((file) => {
            formData.append('addedFiles', file); // 추가된 파일들
          });
        }

        // 삭제할 파일 목록 처리
        if (NewLists.deletedFiles && NewLists.deletedFiles.length) {
          formData.append('deletedFiles', JSON.stringify(NewLists.deletedFiles)); // 삭제할 파일들의 목록
        }

        console.log('formData', formData);

        // API 요청
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/company/companyUpdate`, formData, config);

        // 응답 처리
        if (response.status === 200) {
          dispatch(slice.actions.updateCompany(response.data));
          return { success: true, data: response.data };
        } else {
          return { success: false, message: response.data.message };
        }
      } else {
        // 'Y'가 아닌 경우 기존 로직 처리
        const objData = {
          path: '/company/update',
          id: NewLists.id,
          compName: NewLists.compName,
          compNumber: NewLists.compNumber,
          ceoName: NewLists.ceoName,
          custNo: NewLists.custNo,
          serviceKey: NewLists.serviceKey,
          createUser: NewLists.createUser,
          email: NewLists.email,
          phone: NewLists.phone,
          fax: NewLists.fax,
          zip_code: NewLists.zip_code,
          address: NewLists.address,
          address1: NewLists.address1,
          status: NewLists.status
        };

        const response = await ApiService.post(objData);

        // 응답 처리
        if (response.status === 200) {
          dispatch(slice.actions.updateCompany(response.data));
          return { success: true, data: response.data };
        } else {
          return { success: false, message: response.data.message };
        }
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error: error.message };
    }
  };
}

export function getCompanyDelete(companyId) {
  return async (dispatch) => {
    try {
      const keyId = {
        id: companyId
      };
      const objData = {
        path: '/company/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteCompany(companyId));
      } else {
        dispatch(slice.actions.hasError(response.data.message || '삭제 중 오류 발생'));
      }
    } catch (error) {
      // 오류 메시지를 전달
      dispatch(slice.actions.hasError(error.message || '네트워크 오류 발생'));
    }
  };
}
