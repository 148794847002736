// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  esgProductCarbons: [],
  esgProductCarbon: null,
  relatedEsgProductCarbons: [],
  reviews: [],
  addresses: [],
  isEsgProductCarbonBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'esgProductCarbon',
  initialState,
  reducers: {
    // review esgProductCarbon popup
    reviewEsgProductCarbonPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getEsgProductCarbonsSuccess(state, action) {
    //   state.esgProductCarbon = action.payload;
    // },

    // FILTER PRODUCTS
    filterEsgProductCarbonsSuccess(state, action) {
      state.esgProductCarbon = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    getMonthlyList(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedEsgProductCarbonsSuccess(state, action) {
      state.relatedEsgProductCarbons = action.payload;
    },

    // GET PRODUCT REVIEWS
    getEsgProductCarbonReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isEsgProductCarbonBomDetailOpen = action.payload.isEsgProductCarbonBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get esgProductCarbon details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create EsgProductCarbon
    createEsgProductCarbon(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },

    createEsgProductCarbonExcel(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },

    sendMailEsgProductCarbon(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },

    // update esgProductCarbon
    updateEsgProductCarbon(state, action) {
      const NewEsgProductCarbon = action.payload;
      const EsgProductCarbonUpdate = state.lists.map((item) => {
        if (item.id === NewEsgProductCarbon.id) {
          return NewEsgProductCarbon;
        }
        return item;
      });
      state.lists = EsgProductCarbonUpdate;
    },

    updateEsgMonthlyProduction(state, action) {
      const NewEsgProductCarbon = action.payload;
      const EsgProductCarbonUpdate = state.lists.map((item) => {
        if (item.id === NewEsgProductCarbon.id) {
          return NewEsgProductCarbon;
        }
        return item;
      });
      state.lists = EsgProductCarbonUpdate;
    },

    // delete esgProductCarbon
    deleteEsgProductCarbon(state, action) {
      const esgProductCarbonId = action.payload;
      const deleteEsgProductCarbon = state.lists.filter((list) => list.id !== esgProductCarbonId);
      state.lists = deleteEsgProductCarbon;
    },

    deleteEsgMonthlyProduction(state, action) {
      const esgProductCarbonId = action.payload;
      const deleteEsgMonthlyProduction = state.lists.filter((list) => list.id !== esgProductCarbonId);
      state.lists = deleteEsgMonthlyProduction;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewEsgProductCarbonPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  getMonthlyList,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getEsgProductCarbonList() {
  return async () => {
    try {
      const objData = {
        path: '/esgProductCarbon/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyProductionList() {
  return async () => {
    try {
      const objData = {
        path: '/esgProductCarbon/esgMonthlyProductionList'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getMonthlyList(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductCarbonInsert(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProductCarbon/esgProductCarbonUpload`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgProductCarbon(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductCarbonSendMail(NewLists) {
  // console.log('NewLists', NewLists);
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/esgProductCarbon/esgProductCarbonSendMail`,
        NewLists,
        config
      );

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.sendMailEsgProductCarbon(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

// export function getEsgProductCarbonSendMail(esgProductCarbonId) {
//   return async (dispatch) => {
//     try {
//       const keyId = {
//         id: esgProductCarbonId
//       };
//       const objData = {
//         path: '/esgProductCarbon/esgProductCarbonSendMail',
//         data: keyId
//       };

//       console.log('objData', objData);

//       const response = await ApiService.post(objData);
//       if (response.status === 200) {
//         dispatch(slice.actions.sendMailEsgProductCarbon(esgProductCarbonId));
//         return { success: true };
//       } else {
//         alert(response.data.message);
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function filterEsgProductCarbons(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProductCarbon/filter', { filter });
      dispatch(slice.actions.filterEsgProductCarbonsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductCarbon(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProductCarbon/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedEsgProductCarbons(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProductCarbon/related', { id });
      dispatch(slice.actions.getRelatedEsgProductCarbonsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductCarbonReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getEsgProductCarbonReviewsSuccess(response.data.esgProductCarbonReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductCarbonSingleList(esgProductCarbonId) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProductCarbon/list/single', esgProductCarbonId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductCarbonUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProductCarbon/esgProductCarbonUpdate`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateEsgProductCarbon(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgMonthlyProductionUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();

      data.append('id', NewLists.id);
      data.append('productionAmount', NewLists.productionAmount);
      data.append('salesAmount', NewLists.salesAmount);
      data.append('productionMonth', NewLists.productionMonth);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProductCarbon/esgMonthlyProductionUpdate`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateEsgMonthlyProduction(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductCarbonExcelUpload(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('excels', JSON.stringify(NewLists.excels));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProductCarbon/excelUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgProductCarbonExcel(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductCarbonDelete(esgProductCarbonId) {
  return async () => {
    try {
      const keyId = {
        id: esgProductCarbonId
      };
      const objData = {
        path: '/esgProductCarbon/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgProductCarbon(esgProductCarbonId));
        return { success: true };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgMonthlyProductionDelete(esgProductCarbonId) {
  return async () => {
    try {
      const keyId = {
        id: esgProductCarbonId
      };
      const objData = {
        path: '/esgProductCarbon/esgMonthlyProductionDelete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgProductCarbon(esgProductCarbonId));
        return { success: true };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
