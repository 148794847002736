// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  dashboards: [],
  dashboard: null,
  relatedDashboards: [],
  reviews: [],
  addresses: [],
  isDashboardBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // review dashboard popup
    reviewDashboardPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getDashboardsSuccess(state, action) {
    //   state.dashboard = action.payload;
    // },

    // FILTER PRODUCTS
    filterDashboardsSuccess(state, action) {
      state.dashboard = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    getProductionTotalQty(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedDashboardsSuccess(state, action) {
      state.relatedDashboards = action.payload;
    },

    // GET PRODUCT REVIEWS
    getDashboardReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isDashboardBomDetailOpen = action.payload.isDashboardBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get dashboard details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create Dashboard
    createDashboard(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update dashboard
    updateDashboard(state, action) {
      const NewDashboard = action.payload;
      const DashboardUpdate = state.lists.map((item) => {
        if (item.id === NewDashboard.id) {
          return NewDashboard;
        }
        return item;
      });
      state.lists = DashboardUpdate;
    },

    // delete dashboard
    deleteDashboard(state, action) {
      const dashboardId = action.payload;
      const deleteDashboard = state.lists.filter((list) => list.id !== dashboardId);
      state.lists = deleteDashboard;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewDashboardPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  getProductionTotalQty,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getDashboardProductionTotalQty() {
  return async () => {
    try {
      // const response = await axios.get('/api/dashboard/list');
      const objData = {
        path: '/dashboard/productionTotalQty'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getProductionTotalQty(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardList() {
  return async () => {
    try {
      // const response = await axios.get('/api/dashboard/list');
      const objData = {
        path: '/dashboard/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardInsert(NewLists) {
  return async () => {
    try {
      const data = new FormData();
      data.append('c_code', NewLists.c_code);
      data.append('c_name', NewLists.c_name);
      data.append('c_location', NewLists.c_location);
      data.append('c_open_tm', NewLists.c_open_tm);
      data.append('c_close_tm', NewLists.c_close_tm);
      data.append('file', !!NewLists.files && NewLists.files[0]);

      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/dashboard/dashboardUpload', data, config);
      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createDashboard(result));
      } else {
        alert(response.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    console.log(NewLists);
  };
}

export function filterDashboards(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/dashboard/filter', { filter });
      dispatch(slice.actions.filterDashboardsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboard(id) {
  return async () => {
    try {
      const response = await axios.post('/api/dashboard/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedDashboards(id) {
  return async () => {
    try {
      const response = await axios.post('/api/dashboard/related', { id });
      dispatch(slice.actions.getRelatedDashboardsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getDashboardReviewsSuccess(response.data.dashboardReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardSingleList(dashboardId) {
  return async () => {
    try {
      const response = await axios.post('/api/dashboard/list/single', dashboardId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardUpdate(NewLists, id, flag) {
  return async () => {
    try {
      const objData = {
        path: '/dashboard/update',
        data: NewLists
      };
      let response;

      const data = new FormData();
      switch (flag) {
        case 'Y':
          data.append('id', NewLists.id);
          data.append('c_code', NewLists.c_code);
          data.append('c_name', NewLists.c_name);
          data.append('c_location', NewLists.c_location);
          data.append('c_open_tm', NewLists.c_open_tm);
          data.append('c_close_tm', NewLists.c_close_tm);
          data.append('file', !!NewLists.files && NewLists.files[0]);
          response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/dashboard/dashboardUpdate', data, config);
          break;
        default:
          response = await ApiService.post(objData);
          break;
      }
      if (response.status === 200) {
        dispatch(slice.actions.updateDashboard(response.data));
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardDelete(dashboardId) {
  return async () => {
    try {
      const keyId = {
        id: dashboardId
      };
      const objData = {
        path: '/dashboard/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteDashboard(dashboardId));
        return response.data;
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
