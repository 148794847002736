/* eslint-disable no-undef */
// project imports
import services from 'utils/mockAdapter';
// import { order } from 'data/e-commerce';
import order from 'data/order.json';

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/order/list').reply(200, { order });

services.onPost('/api/order/filter').reply((config) => {
  try {
    const { filter } = JSON.parse(config.data);

    if (filter.sort === 'high') {
      order.sort((a, b) => Number(b.totalPrice) - Number(a.totalPrice));
    }

    if (filter.sort === 'low') {
      order.sort((a, b) => Number(a.totalPrice) - Number(b.totalPrice));
    }

    // if (filter.sort === 'popularity') {
    //   order.sort((a, b) => Number(b.popularity) - Number(a.popularity));
    // }

    // if (filter.sort === 'stock') {
    //   order.sort((a, b) => Number(b.stock) - Number(a.stock));
    // }

    // if (filter.sort === 'new') {
    //   order.sort((a, b) => Number(b.new) - Number(a.new));
    // }

    const results = order.filter((order) => {
      let searchMatches = true;

      if (filter.search) {
        const properties = ['code', 'description', 'totalPrice'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (order[property] && order[property].toString().toLowerCase().includes(filter.search.toString().toLowerCase())) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          searchMatches = false;
        }
      }

      // const genderMatches = filter.gender.length > 0 ? filter.gender.some((item) => item === order.gender) : true;
      // const categoriesMatches =
      //   filter.categories.length > 0 && filter.categories.some((category) => category !== 'all')
      //     ? filter.categories.some((category) => order.categories.some((item) => item === category))
      //     : true;
      // const colorsMatches = filter.colors.length > 0 ? filter.colors.some((color) => order.colors.some((item) => item === color)) : true;

      const minMax = filter.totalPrice ? filter.totalPrice.split('-') : '';
      const totalPriceMatches = filter.totalPrice ? order.totalPrice >= minMax[0] && order.totalPrice <= minMax[1] : true;
      // const ratingMatches = filter.rating > 0 ? order.rating >= filter.rating : true;

      return searchMatches && totalPriceMatches;
    });

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/order/details').reply((config) => {
  try {
    const { id } = JSON.parse(config.data);

    let results;
    if (id === 'default') {
      [results] = order;
    } else {
      // eslint-disable-next-line
      [results] = order?.filter((order) => order.id === Number(id));
    }

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/order/related').reply((config) => {
  try {
    const { id } = JSON.parse(config.data);

    const results = order.filter((order) => order.id !== Number(id));

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/order/list/single').reply((config) => {
  try {
    const result = order.find((e) => e.id === Number(config.data));
    return [200, result];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/order/insert').reply((config) => {
  try {
    let list = JSON.parse(config.data);
    list = {
      ...list,
      id: order.length + 1
    };
    order.push(list);
    return [200, { list }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/order/update').reply((config) => {
  try {
    const list = JSON.parse(config.data);

    let NewOrder = null;
    _.map(order, (_list) => {
      if (_list.id === list.id) {
        _.assign(_list, { ...list });
        NewOrder = _list;
      }
      return _list;
    });

    return [200, { NewOrder }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
