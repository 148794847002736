// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  contactUss: [],
  contactUs: null,
  relatedContactUss: [],
  reviews: [],
  addresses: [],
  isContactUsBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    // review contactUs popup
    reviewContactUsPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getContactUssSuccess(state, action) {
    //   state.contactUs = action.payload;
    // },

    // FILTER PRODUCTS
    filterContactUssSuccess(state, action) {
      state.contactUs = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedContactUssSuccess(state, action) {
      state.relatedContactUss = action.payload;
    },

    // GET PRODUCT REVIEWS
    getContactUsReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isContactUsBomDetailOpen = action.payload.isContactUsBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get contactUs details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create ContactUs
    createContactUs(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update contactUs
    updateContactUs(state, action) {
      const NewContactUs = action.payload;
      const ContactUsUpdate = state.lists.map((item) => {
        if (item.id === NewContactUs.id) {
          return NewContactUs;
        }
        return item;
      });
      state.lists = ContactUsUpdate;
    },

    // delete contactUs
    deleteContactUs(state, action) {
      const contactUsId = action.payload;
      const deleteContactUs = state.lists.filter((list) => list.id !== contactUsId);
      state.lists = deleteContactUs;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewContactUsPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getContactUsList() {
  return async () => {
    try {
      const objData = {
        path: '/contactUs/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactUsInsert(NewLists) {
  // console.log('Reducers NewLists Data', NewLists);

  return async () => {
    // Make sure to include dispatch here
    try {
      const data = new FormData();
      data.append('name', NewLists.name);
      data.append('company', NewLists.company);
      data.append('email', NewLists.email);
      data.append('phone', NewLists.phone);
      data.append('message', NewLists.message);
      data.append('agreement', NewLists.agreement);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contactUs/contactUsUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createContactUs(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function filterContactUss(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/contactUs/filter', { filter });
      dispatch(slice.actions.filterContactUssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactUs(id) {
  return async () => {
    try {
      const response = await axios.post('/api/contactUs/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedContactUss(id) {
  return async () => {
    try {
      const response = await axios.post('/api/contactUs/related', { id });
      dispatch(slice.actions.getRelatedContactUssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactUsReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getContactUsReviewsSuccess(response.data.contactUsReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactUsSingleList(contactUsId) {
  return async () => {
    try {
      const response = await axios.post('/api/contactUs/list/single', contactUsId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactUsUpdate(NewLists, id, flag) {
  return async () => {
    try {
      const data = new FormData();
      data.append('id', NewLists.id);
      data.append('part_name', NewLists.part_name);
      data.append('model', NewLists.model);
      data.append('part_number', NewLists.part_number);
      data.append('code', NewLists.code);
      data.append('carbon_emission', NewLists.carbon_emission);
      data.append('categories', NewLists.categories);
      data.append('type', NewLists.type);
      data.append('description', NewLists.description);
      data.append('unit', NewLists.unit);
      data.append('unit_quantity', NewLists.unit_quantity);
      data.append('offerPrice', NewLists.offerPrice);
      data.append('reorder_point', NewLists.reorder_point);
      data.append('reorder_quantity', NewLists.reorder_quantity);
      data.append('minimum_order_quantity', NewLists.minimum_order_quantity);
      data.append('maximum_order_quantity', NewLists.maximum_order_quantity);
      data.append('location', NewLists.location);
      data.append('c_company_id', NewLists.c_company_id);
      data.append('c_manager_id', NewLists.c_manager_id);
      data.append('file', !!NewLists.files && NewLists.files[0]);
      data.append('bomItems', JSON.stringify(NewLists.bomItems));
      data.append('lineBomItems', JSON.stringify(NewLists.lineBomItems));

      let response;

      if (flag === 'Y') {
        // `Y` 플래그에 대한 처리
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contactUs/contactUsUpdate`, data, config);
      } else {
        // `Y` 플래그가 아닐 때의 처리
        const objData = {
          path: '/contactUs/update',
          data: NewLists
        };

        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateContactUs(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getContactUsDelete(contactUsId) {
  return async () => {
    try {
      const keyId = {
        id: contactUsId
      };
      const objData = {
        path: '/contactUs/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteContactUs(contactUsId));
        return { success: true };
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
