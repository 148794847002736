// assets
import { AppstoreAddOutlined, ClusterOutlined } from '@ant-design/icons';

// icons
const icons = {
  AppstoreAddOutlined,
  ClusterOutlined
};
// ==============================|| MENU ITEMS - mes ||============================== //

const esg = {
  id: 'esgSupplyChain',
  title: 'ESG Supply Chain',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'esg-esgProductSupplyChain',
      title: '공급망 제품',
      type: 'item',
      url: '/esgSupplyChain/esgProductSupplyChain/list',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgSupplyChain',
      title: '공급망',
      type: 'item',
      url: '/esgSupplyChain/esgSupplyChain/list',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgProductSupplyChainCarbon',
      title: '공급망 탄소배출',
      type: 'item',
      url: '/esgSupplyChain/esgProductSupplyChainCarbon/list',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    }
  ]
};

export default esg;
