import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// notification
const AppNotificationVerInfo = Loadable(lazy(() => import('pages/notification/verInfo/default')));

const AppNotificationVerInfoList = Loadable(lazy(() => import('pages/notification/list')));
const AppNotificationVerInfoCreate = Loadable(lazy(() => import('pages/notification/create')));
const AppNotificationVerInfoUEdit = Loadable(lazy(() => import('pages/notification/edit')));

// ==============================|| NOTIFICATION ROUTING ||============================== //
const NotificationRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'notification',
          children: [
            {
              path: 'list',
              element: <AppNotificationVerInfoList />
            },
            {
              path: 'create',
              element: <AppNotificationVerInfoCreate />
            },
            {
              path: 'edit/:id',
              element: <AppNotificationVerInfoUEdit />
            }
          ]
        },
        {
          path: 'notification',
          children: [
            {
              path: 'version',
              element: <AppNotificationVerInfo />
            }
          ]
        }
      ]
    }
  ]
};

export default NotificationRoutes;
