/* eslint-disable no-undef */
// project imports
import services from 'utils/mockAdapter';
// import { materials } from 'data/e-commerce';
import materials from 'data/materials.json';

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/materials/list').reply(200, { materials });

services.onPost('/api/materials/filter').reply((config) => {
  try {
    const { filter } = JSON.parse(config.data);

    if (filter.sort === 'high') {
      materials.sort((a, b) => Number(b.offerPrice) - Number(a.offerPrice));
    }

    if (filter.sort === 'low') {
      materials.sort((a, b) => Number(a.offerPrice) - Number(b.offerPrice));
    }

    // if (filter.sort === 'popularity') {
    //   materials.sort((a, b) => Number(b.popularity) - Number(a.popularity));
    // }

    if (filter.sort === 'stock') {
      materials.sort((a, b) => Number(b.stock) - Number(a.stock));
    }

    // if (filter.sort === 'new') {
    //   materials.sort((a, b) => Number(b.new) - Number(a.new));
    // }

    const results = materials.filter((materials) => {
      let searchMatches = true;

      if (filter.search) {
        const properties = ['name', 'description', 'rating', 'offerPrice'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (materials[property] && materials[property].toString().toLowerCase().includes(filter.search.toString().toLowerCase())) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          searchMatches = false;
        }
      }

      // const genderMatches = filter.gender.length > 0 ? filter.gender.some((item) => item === materials.gender) : true;
      const categoriesMatches =
        filter.categories.length > 0 && filter.categories.some((category) => category !== 'all')
          ? filter.categories.some((category) => materials.categories.some((item) => item === category))
          : true;
      // const colorsMatches = filter.colors.length > 0 ? filter.colors.some((color) => materials.colors.some((item) => item === color)) : true;

      const minMax = filter.offerPrice ? filter.offerPrice.split('-') : '';
      const offerPriceMatches = filter.offerPrice ? materials.offerPrice >= minMax[0] && materials.offerPrice <= minMax[1] : true;
      const ratingMatches = filter.rating > 0 ? materials.rating >= filter.rating : true;

      return searchMatches && categoriesMatches && offerPriceMatches && ratingMatches;
    });

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/materials/details').reply((config) => {
  try {
    const { id } = JSON.parse(config.data);

    let results;
    if (id === 'default') {
      [results] = materials;
    } else {
      // eslint-disable-next-line
      [results] = materials?.filter((materials) => materials.id === Number(id));
    }

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/materials/related').reply((config) => {
  try {
    const { id } = JSON.parse(config.data);

    const results = materials.filter((materials) => materials.id !== Number(id));

    return [200, results];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/materials/list/single').reply((config) => {
  try {
    const result = materials.find((e) => e.id === Number(config.data));
    return [200, result];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/materials/insert').reply((config) => {
  try {
    let list = JSON.parse(config.data);
    list = {
      ...list,
      id: materials.length + 1
    };
    materials.push(list);
    return [200, { list }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/materials/update').reply((config) => {
  try {
    const list = JSON.parse(config.data);

    let NewMaterials = null;
    _.map(materials, (_list) => {
      if (_list.id === list.id) {
        _.assign(_list, { ...list });
        NewMaterials = _list;
      }
      return _list;
    });

    return [200, { NewMaterials }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
