import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - bbs
const BbsQnaList = Loadable(lazy(() => import('pages/bbs/qna/list')));
const BbsQnaCreate = Loadable(lazy(() => import('pages/bbs/qna/create')));
const BbsQnaEdit = Loadable(lazy(() => import('pages/bbs/qna/edit')));
const BbsQnaDescription = Loadable(lazy(() => import('pages/bbs/qna/description')));
const BbsNewsList = Loadable(lazy(() => import('pages/bbs/news/list')));
const BbsNewsOGList = Loadable(lazy(() => import('pages/bbs/news/listOG')));
const BbsNewsCreate = Loadable(lazy(() => import('pages/bbs/news/create')));
const BbsNewsEdit = Loadable(lazy(() => import('pages/bbs/news/edit')));
const BbsNewsDescription = Loadable(lazy(() => import('pages/bbs/news/description')));

const Manual = Loadable(lazy(() => import('pages/bbs/manual')));

// ==============================|| AUTH ROUTING ||============================== //

const CommonRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'bbs',
          children: [
            {
              path: 'qna',
              element: <BbsQnaList />
            },
            {
              path: 'qna_create',
              element: <BbsQnaCreate />
            },
            {
              path: 'qna_edit/:id',
              element: <BbsQnaEdit />
            },
            {
              path: 'qna_description/:id',
              element: <BbsQnaDescription />
            },
            {
              path: 'news',
              element: <BbsNewsList />
            },
            {
              path: 'news_og',
              element: <BbsNewsOGList />
            },
            {
              path: 'news_create',
              element: <BbsNewsCreate />
            },
            {
              path: 'news_edit/:id',
              element: <BbsNewsEdit />
            },
            {
              path: 'news_description/:id',
              element: <BbsNewsDescription />
            },
            {
              path: 'manual',
              element: <Manual />
            }
          ]
        }
      ]
    }
  ]
};

export default CommonRoutes;
