import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - eag
const EagDashboard = Loadable(lazy(() => import('pages/esg/dashboard/default')));

const EsgMonthlyCarbonEmissionsList = Loadable(lazy(() => import('pages/esg/esgMonthlyCarbonEmissions/list')));
const EsgMonthlyCarbonEmissionsCreate = Loadable(lazy(() => import('pages/esg/esgMonthlyCarbonEmissions/create')));
const EsgMonthlyCarbonEmissionsEdit = Loadable(lazy(() => import('pages/esg/esgMonthlyCarbonEmissions/edit')));
// const EsgMonthlyCarbonEmissionsDetails = Loadable(lazy(() => import('pages/esg/esgMonthlyCarbonEmissions/details')));

const EsgProductCarbonList = Loadable(lazy(() => import('pages/esg/esgProductCarbon/list')));
const EsgProductCarbonCreate = Loadable(lazy(() => import('pages/esg/esgProductCarbon/create')));
const EsgProductCarbonEdit = Loadable(lazy(() => import('pages/esg/esgProductCarbon/edit')));
const EsgProductCarbonDetails = Loadable(lazy(() => import('pages/esg/esgProductCarbon/details')));

const EsgCustomerList = Loadable(lazy(() => import('pages/esg/esgCustomer/list')));
const EsgCustomerCreate = Loadable(lazy(() => import('pages/esg/esgCustomer/create')));
const EsgCustomerEdit = Loadable(lazy(() => import('pages/esg/esgCustomer/edit')));

const EsgProductList = Loadable(lazy(() => import('pages/esg/esgProduct/list')));
const EsgProductCreate = Loadable(lazy(() => import('pages/esg/esgProduct/create')));
const EsgProductEdit = Loadable(lazy(() => import('pages/esg/esgProduct/edit')));

// ==============================|| eag ROUTING ||============================== //

const EsgRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'esg',
          children: [
            {
              path: 'dashboard',
              element: <EagDashboard />
            },
            {
              path: 'esgProductCarbon',
              children: [
                {
                  path: 'list',
                  element: <EsgProductCarbonList />
                },
                {
                  path: 'create',
                  element: <EsgProductCarbonCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgProductCarbonEdit />
                },
                {
                  path: 'details/:id',
                  element: <EsgProductCarbonDetails />
                }
              ]
            },
            {
              path: 'esgMonthlyCarbonEmissions',
              children: [
                {
                  path: 'list',
                  element: <EsgMonthlyCarbonEmissionsList />
                },
                {
                  path: 'create',
                  element: <EsgMonthlyCarbonEmissionsCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgMonthlyCarbonEmissionsEdit />
                }
              ]
            },
            {
              path: 'esgProduct',
              children: [
                {
                  path: 'list',
                  element: <EsgProductList />
                },
                {
                  path: 'create',
                  element: <EsgProductCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgProductEdit />
                }
              ]
            },
            {
              path: 'esgCustomer',
              children: [
                {
                  path: 'list',
                  element: <EsgCustomerList />
                },
                {
                  path: 'create',
                  element: <EsgCustomerCreate />
                },
                {
                  path: 'edit/:id',
                  element: <EsgCustomerEdit />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default EsgRoutes;
